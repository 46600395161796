import React from "react";
import { useTranslation } from "react-i18next";
import img from "../../assets/index";

export const ChangeOnlinePromo = () => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <h1>
          <span className="light"> {t("online.payment.owner")} </span>
          <span className="normal"> {t("online.payment.program")} </span>
          <span className="dark"> NN Direct Health;</span>
        </h1>
      </div>
      <div>
        <p>{t("online.payment.update-payment")}</p>
        <div className="master-visa">
          <img src={img.visa_icon} />
          <img src={img.master_icon} />
        </div>
      </div>
    </>
  );
};
