import React, { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Select, Button } from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import { useTranslations as useTranslationsNN } from "../../Context/i18n";
import OnlinePaymentStore from "../../Stores/OnlinePaymentStore";
import ContractStore from "../../Stores/ContractStore";
import PaymentStore from "../../Stores/PaymentStore";
import { setDateFormatForDateTime } from "../CommonComp/Functions";
import CheckBox from "../Inbox/CheckBox";
import "../../Css/OnlinePayment/Online-payment.css";
import LoaderStore from "../../Stores/LoaderStore";
import ModalStore from "../../Stores/ModalStore";

const ChangeCardStep1 = () => {
  const { t } = useTranslation();
  const { language } = useTranslationsNN();
  const { paymentList, paymentHasContent } = useContext(PaymentStore);
  const contractStore = useContext(ContractStore);
  const { contractList, contractsHasContent, paymentMethodDetails } =
    contractStore;
  const onlinePaymentStore = useContext(OnlinePaymentStore);
  const loaderStore = useContext(LoaderStore);
  const { toggleTerms } = useContext(ModalStore);
  const [conList, setConList] = useState(null);
  const [toggleClass, setToggleClass] = useState("alt");
  const [dropDownMenuValues, setDropDownMenuValues] = useState([]);
  const [contractSelected, setContractSelected] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [activeSubmit, setActiveSubmit] = useState(false);
  const [noContent, setNoContent] = useState();
  const [paymentNumber, setPaymentNumber] = useState("");
  const [paymentMethodDetail, setPaymentMethodDetail] = useState(undefined);
  const [hasPendingText, setHasPendingText] = useState(undefined);

  const [myUrl, setMyUrl] = useState("/online-payment");
  const navigate = useNavigate();

  useEffect(() => {
    let contracts = contractList.filter(
      (contract) =>
        contract.ProductCode === "HM1" && contract.StatusId === "IF",
    );
    setConList(contracts);
  }, [contractList]);

  const checkHasPendingActions = useCallback(
    (contractNumber) => {
      if (
        contractList.some(
          (contract) =>
            contract.DisplayNumber === contractNumber &&
            contract.PaymentMethodId !== "C",
        )
      ) {
        loaderStore.setLoaderForData(false);
        return setHasPendingText(t("online.payment.change-card-pending-msg"));
      }
      if (
        paymentList.some(
          (payment) =>
            payment.DisplayNumber === contractNumber &&
            payment.Payments.some((elem) => elem.StatusCode === "O"),
        )
      ) {
        loaderStore.setLoaderForData(false);
        return setHasPendingText(t("online.payment.change-card-fix-msg"));
      }
      setHasPendingText(undefined);
    },
    [language],
  );

  useEffect(() => {
    checkHasPendingActions(contractSelected);
    if (contractSelected && !hasPendingText && paymentMethodDetails) {
      let payment = paymentList.find(
        (payment) => payment.DisplayNumber === contractSelected,
      );
      let contract = contractList.find(
        (payment) => payment.DisplayNumber === contractSelected,
      );
      let { ContractDetails } = paymentMethodDetails.find(
        (e) => e[contractSelected],
      )[contractSelected];
      setPaymentMethodDetail(ContractDetails);
      let onlinePayment = {
        ContractNumber: contractSelected,
        OutstandingAmount: 0,
        PaymentMethodId: contract.PaymentMethodId,
        ProductCode: contract.ProductCode,
        PaymentFrequencyId: contract.PaymentFrequencyId,
        ApplicationNumber: contract.ApplicationNumber,
        MaskedAccountNumber: paymentMethodDetail?.MaskedAccountNumber,
        ExpirationDate: paymentMethodDetail?.ExpirationDate,
      };
      if (!payment) {
        payment = {
          ...onlinePayment,
          PaymentCodeA: paymentMethodDetail?.AccountNumber,
          ApplicationNumber: contract.ApplicationNumber,
        };
      } else {
        payment = {
          ...payment,
          ...onlinePayment,
        };
      }
      onlinePaymentStore.setContract(contract);
      onlinePaymentStore.setPayment(payment);
    }
  }, [
    contractSelected,
    paymentMethodDetails,
    hasPendingText,
    paymentMethodDetail,
  ]);

  const dropDownHandler = async (e) => {
    setPaymentNumber(e.target.value);
    var res = e.target.value.split(" ");
    let contractNumber = res[res.length - 1];
    setContractSelected(contractNumber);
    if (e.target.value !== "all") {
      if (!paymentMethodDetails) {
        let contractData = conList.map(
          ({ ApplicationNumber, DisplayNumber }) => ({
            ApplicationNumber,
            DisplayNumber,
          }),
        );
        loaderStore.setLoaderForData(true);
        await contractStore.loadAllContractsBankAccountDetails(contractData);
      }
      setNoContent(false);
    } else {
      setNoContent(true);
    }
  };

  const checked = {
    isChecked,
    setIsChecked,
  };

  useEffect(() => {
    if (conList) {
      let contractOptions = conList.map(
        ({ ProductName, Number }) => `${ProductName + " " + Number}`,
      );
      setDropDownMenuValues(contractOptions);
    }
  }, [conList]);

  const handleOnClick = () => {
    navigate(myUrl);
  };

  useEffect(() => {
    if (isChecked && !hasPendingText) {
      setToggleClass("");
      setActiveSubmit(true);
      setMyUrl("/online-payment/2");
    } else {
      setToggleClass("alt");
      setActiveSubmit(false);
      setMyUrl("/online-payment");
    }
  }, [isChecked, hasPendingText]);

  useEffect(() => {
    if (dropDownMenuValues.length !== 0) {
      loaderStore.setLoaderForData(false);
    } else {
      loaderStore.setLoaderForData(true);
    }
    if (contractsHasContent) {
      loaderStore.setLoaderForData(false);
    }
    if (paymentMethodDetails) {
      loaderStore.setLoaderForData(false);
    }
  }, [dropDownMenuValues, paymentHasContent, paymentMethodDetails]);

  function dropDownListItem(array) {
    let list = array.map((elementDrop) => {
      return {
        text: elementDrop,
        value: elementDrop,
      };
    });
    return list;
  }
  return (
    <div>
      <>
        {dropDownMenuValues.length !== 0 ? (
          <>
            <h2>
              <span className="light">
                {t("online.payment.change-card-select-text")}
              </span>{" "}
            </h2>
            <div className="online-payment-container">
              <Select
                placeholderText={`${t("global.choose")}...`}
                labelText={t("global.insurance-policy")}
                name="select"
                onChange={dropDownHandler}
                options={dropDownListItem(
                  dropDownMenuValues,
                  `${t("global.choose")}...`,
                )}
                testId="select"
                value={paymentNumber}
                mb={"10px"}
              />
            </div>
          </>
        ) : (
          <>
            <h2 className="no-payment-text">
              <span>{t("online.payment.option-not-available")}</span>
            </h2>
            <Button onClick={() => navigate("/home")} variant={"PrimaryMedium"}>
              {t("online.payment.return-original")}
            </Button>
          </>
        )}
      </>
      {(paymentMethodDetail && noContent === false) || hasPendingText ? (
        <>
          <div className="online-payment-form">
            <div className="form-element right-padded"></div>
            <div className="form-element right-padded">
              <span className="payBold">{t("contracts.insurance-number")}</span>
              <span> {contractSelected} </span>
            </div>
            {hasPendingText ? (
              <div className="form-element right-padded">
                <span className="pending-text">{hasPendingText}</span>{" "}
              </div>
            ) : (
              <>
                <div className="form-element right-padded">
                  <span className="payBold">
                    {t("online.payment.card-number")}{" "}
                  </span>
                  <span> {paymentMethodDetail.MaskedAccountNumber} </span>
                </div>
                <div className="form-element right-padded">
                  <span className="payBold">
                    {`${t("global.expiry-date")}:`}{" "}
                  </span>
                  <span>
                    {" "}
                    {setDateFormatForDateTime(
                      paymentMethodDetail.ExpirationDate,
                    )}{" "}
                  </span>
                </div>
                <div className="form-element right-padded"></div>
                <div className="form-element checkbox nn">
                  <label>{t("online.payment.accept-terms-use")}</label>
                  <div className=" checkbox-container-register-3">
                    <CheckBox
                      id={"checkbox-register-3"}
                      dataName={"useTerms"}
                      data={[checked, "normal"]}
                      innerText={t("online.payment.text-terms-use")}
                    />
                    <div className="anchor-modal">
                      <a
                        onClick={() => toggleTerms()}
                        className="link"
                        data-toggle="modal"
                        data-target="#modal-terms"
                      >
                        {t("global.terms_of_use")}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="disclaimer-body">
                  <Button
                    mr={"20px"}
                    onClick={handleOnClick}
                    variant={activeSubmit ? "PrimaryMedium" : "SecondaryMedium"}
                  >
                    {t("global.next")}
                  </Button>
                  {toggleClass === "" ? (
                    <span className="disclaimer1">
                      {t("online.payment.redirection-msg")}
                    </span>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ChangeCardStep1;
