import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OnlinePaymentStore from "../../Stores/OnlinePaymentStore";
import { useTranslation } from "react-i18next";
import PaymentStore from "../../Stores/PaymentStore";
import {
  Icon,
  Select,
  Button,
} from "@nn-design-system/react-component-library";
import {
  ConvertDecimalToString,
  setDateFormatForDateTime,
} from "../CommonComp/Functions";
import CheckBox from "../Inbox/CheckBox";
import "../../Css/OnlinePayment/Online-payment.css";
import LoaderStore from "../../Stores/LoaderStore";
import ModalStore from "../../Stores/ModalStore";

const OnlinePaymentStep1 = () => {
  const { t } = useTranslation();
  const { paymentList, paymentHasContent } = useContext(PaymentStore);
  const onlinePaymentStore = useContext(OnlinePaymentStore);
  const { payment } = onlinePaymentStore;
  const loaderStore = useContext(LoaderStore);
  const { toggleTerms } = useContext(ModalStore);
  const [conList, setConList] = useState(null);
  const [toggleClass, setToggleClass] = useState("alt");
  const [dropDownMenuValues, setDropDownMenuValues] = useState([]);
  const [contracts, setContracts] = useState(null);
  const [payments, setPayment] = useState(payment);
  const [isChecked, setIsChecked] = useState(false);
  const [activeSubmit, setActiveSubmit] = useState(false);
  const [noContent, setNoContent] = useState();
  const [paymentNumber, setPaymentNumber] = useState("");

  const [myUrl, setMyUrl] = useState("/online-payment");
  const [dropdownClass, setDropdownClass] = useState("grey");
  const navigate = useNavigate();

  const dropDownHandler = (e) => {
    setPaymentNumber(e.target.value);
    var res = e.target.value.split(" ");
    if (e.target.value !== "all") {
      let contractWithPayments = conList.find(
        (p) => p.Number === res[res.length - 1],
      );
      setContracts(contractWithPayments);
      setNoContent(false);
      setDropdownClass("");
    } else {
      setNoContent(true);
      setDropdownClass("grey");
    }
  };

  const checked = {
    isChecked,
    setIsChecked,
  };

  useEffect(() => {
    if (paymentList) {
      let contractWithPaymentList = [];
      paymentList.map((elem, i) => {
        if (elem.IsNn2 !== true) {
          let sortedList = { ...elem };
          sortedList.Payments = elem.Payments.slice().sort((a, b) =>
            b.InstallmentFrom < a.InstallmentFrom ? 1 : -1,
          );
          let payments = sortedList.Payments.find((p) => p.StatusCode === "O");
          if (payments) {
            contractWithPaymentList.push(sortedList);
          }
        }
      });
      setConList(contractWithPaymentList);
    }
  }, [paymentList]);

  useEffect(() => {
    if (conList) {
      let dropDownContractArray = [];
      conList.map((elem, i) => {
        let newVal = elem.ProductName + " " + elem.Number;
        dropDownContractArray.push(newVal);
      });
      setDropDownMenuValues(dropDownContractArray);
    }
  }, [conList]);

  useEffect(() => {
    if (contracts) {
      let payments = contracts.Payments.find((p) => p.StatusCode === "O");
      payments.PaymentFrequencyId = contracts.PaymentFrequencyId;
      payments.ProductCode = contracts.ProductCode;
      payments.PaymentMethodId = contracts.PaymentMethodId;
      setPayment(payments);
      onlinePaymentStore.setPayment(payments);
      onlinePaymentStore.setContract(contracts);
    }
  }, [contracts]);

  useEffect(() => {
    if (isChecked) {
      setToggleClass("");
      setActiveSubmit(true);
      setMyUrl("/online-payment/2");
    } else {
      setToggleClass("alt");
      setActiveSubmit(false);
      setMyUrl("/online-payment");
    }
  }, [isChecked]);

  useEffect(() => {
    if (dropDownMenuValues.length !== 0) {
      loaderStore.setLoaderForData(false);
    } else {
      loaderStore.setLoaderForData(true);
    }
    if (paymentHasContent) {
      loaderStore.setLoaderForData(false);
    }
  }, [dropDownMenuValues, paymentHasContent]);

  function dropDownListItem(array) {
    let list = array.map((elementDrop) => {
      return {
        text: elementDrop,
        value: elementDrop,
      };
    });
    return list;
  }

  return (
    <div>
      <>
        {dropDownMenuValues.length !== 0 ? (
          <>
            <h2>
              <span className="light">
                {t("online.payment.insurance-selection")}
              </span>{" "}
            </h2>
            <div className="online-payment-container">
              <Select
                placeholderText={`${t("global.choose")}...`}
                labelText={t("global.insurance-policy")}
                name="select"
                onChange={dropDownHandler}
                options={dropDownListItem(
                  dropDownMenuValues,
                  `${t("global.choose")}...`,
                )}
                testId="select"
                value={paymentNumber}
                mb={"10px"}
              />
            </div>
          </>
        ) : (
          <>
            <h2 className="no-payment-text">
              <span>{t("online.payment.visit-section")} </span>
              <span className="normal">{t("online.payment.my-payments")}</span>
              <span> {t("online.payment.no-payments-text")}</span>
            </h2>
            <Button onClick={() => navigate("/home")} variant={"PrimaryMedium"}>
              {t("global.return-home")}
            </Button>
          </>
        )}
      </>
      {payments && noContent === false ? (
        <>
          <div className="online-payment-form">
            <div className="form-element right-padded">
              <label>{t("online.payment.payment-amount")}</label>
              <span className="light payamount">
                {ConvertDecimalToString(payments.OutstandingAmount) + " "}
              </span>
              <span className="payFrequency payBold">
                {contracts.PaymentFrequency}
              </span>
            </div>
            <div className="form-element right-padded">
              <span className="payBold">
                {t("contracts.insurance-number")}:
              </span>
              <span> {payments.ContractNumber} </span>
            </div>
            <div className="form-element right-padded">
              <span className="payBold">
                {t("online.payment.code-payment")}:
              </span>
              <span> {payments.PaymentCodeA} </span>
            </div>
            <div className="form-element right-padded">
              <span className="payBold">
                {t("online.payment.date-debtor")}:{" "}
              </span>
              <span>
                {" "}
                {setDateFormatForDateTime(payments.InstallmentFrom)}{" "}
              </span>
            </div>
            <div className="form-element right-padded">
              <span className="payBold">{t("global.condition")}:</span>{" "}
              <span className="light">{payments.StatusDescription} </span>
            </div>
            <div className="form-element right-padded">
              <div className="chevron-link-new">
                <Link to="/myPayments">
                  <Icon
                    mr={"10px"}
                    mt={"5px"}
                    mb={"-3px"}
                    name="ChevronRight"
                    variant="Small"
                    color="#EA650D"
                  />
                  <span>{t("online.payment.my-payments")}</span>
                </Link>
              </div>
            </div>

            <div className="form-element checkbox nn">
              <label>{t("online.payment.accept-terms-use")}</label>
              <div className=" checkbox-container-register-3">
                <CheckBox
                  id={"checkbox-register-3"}
                  dataName={"useTerms"}
                  data={[checked, "normal"]}
                  innerText={t("online.payment.text-terms-use")}
                />
                <div className="anchor-modal">
                  <a
                    onClick={() => toggleTerms()}
                    className="link"
                    data-toggle="modal"
                    data-target="#modal-terms"
                  >
                    {t("global.terms_of_use")}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="disclaimer-body">
            <Button
              mr={"20px"}
              onClick={() => navigate(myUrl)}
              variant={activeSubmit ? "PrimaryMedium" : "SecondaryMedium"}
            >
              {t("global.next")}
            </Button>
            {toggleClass === "" ? (
              <span className="disclaimer1">
                {t("online.payment.redirection-to-complete-msg")}
              </span>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default OnlinePaymentStep1;
