import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect,
} from "react";
import { observer } from "mobx-react-lite";
import {
  Select,
  Heading,
  DatepickerInput,
  Button,
  Checkbox,
  Popover,
  Paragraph,
  Label,
  // Autocomplete as NNAutocomplete,
  PrefabModal,
} from "@nn-design-system/react-component-library";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import DOMPurify from "dompurify";
import { ExpenseCategory } from "../../../Consts/Claims";
import ClaimsStore from "../../../Stores/ClaimsStore";
import LoaderStore from "../../../Stores/LoaderStore";
import SettingsStore from "../../../Stores/SettingsStore";
import ContractStore from "../../../Stores/ContractStore";
import EclaimsTermsAndConditions from "../../TextDocuments/EclaimsTermsAndConditions";
import ConfirmationModal from "./ConfirmationModal";
import NoGroupPolicyBenefitsModal from "./NoGroupPolicyBenefitsModal";
import styles from "../../../Css/Claims/ClaimSubmissionWizard.module.scss";
import { Autocomplete } from "../../Commons/Autocomplete";
import { useTranslations as useTranslationsNN } from "../../../Context/i18n";
let diseasesSearchTimeoutId = 0;

const BasicSubmissionInfo = (props) => {
  const { t } = useTranslation();
  const { language } = useTranslationsNN();
  const { privateSettings } = useContext(SettingsStore);
  const claimsStore = useContext(ClaimsStore);
  const contractStore = useContext(ContractStore);
  const loaderStore = useContext(LoaderStore);
  const {
    groupPoliciesWithEligibilitySubmission,
    groupPoliciesWithEligibilitySubmissionHasContent,
  } = claimsStore;
  const {
    groupContractsCoverages,
    groupContractsCoveragesHasContent,
    selectedContractDetails,
  } = contractStore;

  const [contractValue, setContractValue] = useState("");
  const [contractValueError, setContractValueError] = useState("");
  const [contractValueIsValid, setContractValueIsValid] = useState(false);
  const [mainInsuredMemberValue, setMainInsuredMemberValue] = useState("");
  const [mainInsuredMemberState, setMainInsuredMemberState] =
    useState("Disabled");
  const [mainInsuredMemberValueError, setMainInsuredMemberValueError] =
    useState("");
  const [claimReasonValue, setClaimReasonValue] = useState("");
  const [claimReasonValueError, setClaimReasonValueError] = useState("");
  const [claimReasonCode, setClaimReasonCode] = useState("");
  const [expenseCategoryValue, setExpenseCategoryValue] = useState("");
  const [expenseCategoryState, setExpenseCategoryState] = useState("Disabled");
  const [expenseCategoryValueError, setExpenseCategoryValueError] =
    useState("");
  const [eventDateValue, setEventDateValue] = useState(null);
  const [eventDateValueError, setEventDateValueError] = useState("");
  const [eventDateValueIsValid, setEventDateValueIsValid] = useState(false);
  const [termsAndConditionsValue, setTermsAndConditionsValue] = useState("");
  const [diseases, setDiseases] = useState([]);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showBackConfirmationModal, setShowBackConfirmationModal] =
    useState(false);
  const [eligibleContracts, setEligibleContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState(null);
  const [insuredMembers, setInsuredMembers] = useState([]);

  const [isAutocompleteLoading, setIsAutocompleteLoading] = useState(false);
  const defaultAutocompleteText = t("claim.submission.autocomplete-text");
  const autocompleteNoResultText = t("claim.submission.no-results");
  const [autocompleteNoOptionText, setAutocompleteNoOptionText] = useState(
    defaultAutocompleteText,
  );
  const [filteredExpenseCategories, setFilteredExpenseCategories] = useState(
    [],
  );

  const [minimumIncidentDate, setMinimumIncidentDate] = useState(
    moment().subtract(12, "months").calendar(),
  );
  const [showNoGroupPolicyBenefitsModal, setShowNoGroupPolicyBenefitsModal] =
    useState(false);

  const [
    showPolicyHolderStopPaymentDateError,
    setShowPolicyHolderStopPaymentDateError,
  ] = useState(false);

  const invalidValueText = t("claim.submission.invalid-value");

  const { expenseCategories, policyBenefitsHasValue } = claimsStore;

  const dateOutOfRangeValidatioMessage = useMemo(() => {
    return DOMPurify.sanitize(
      privateSettings.eClaims.ResourceStrings[
        "NN.Claim.Submission.DateOutOfRangeValidation"
      ],
      { USE_PROFILES: { html: true } },
    );
  }, [privateSettings.eClaims.ResourceStrings]);

  const policyholderStopPaymentValidationMessage = useMemo(() => {
    return DOMPurify.sanitize(
      privateSettings.eClaims.ResourceStrings[
        "NN.Claim.Submission.PolicyholderStopPaymentValidation"
      ],
      { USE_PROFILES: { html: true } },
    );
  }, [privateSettings.eClaims.ResourceStrings]);

  const validateContractValue = useCallback(
    (value) => {
      if (value !== "") {
        setContractValueError("");
        setContractValueIsValid(true);
        return true;
      }
      setContractValueError(invalidValueText);
      setContractValueIsValid(false);
      return false;
    },
    [setContractValueError, setContractValueIsValid],
  );

  const validateMainInsuredMemberValue = useCallback(
    (value) => {
      if (value !== "") {
        setMainInsuredMemberValueError("");
        return true;
      }
      setMainInsuredMemberValueError(invalidValueText);
      return false;
    },
    [setMainInsuredMemberValueError],
  );

  const validateClaimReasonCode = useCallback(
    (value) => {
      if (value && value !== "") {
        setClaimReasonValueError("");
        return true;
      }
      setClaimReasonValueError(invalidValueText);
      return false;
    },
    [setClaimReasonValueError],
  );

  const handleOnChangeDiseasesAutoSearch = useCallback(
    (searchTerm) => {
      clearTimeout(diseasesSearchTimeoutId);
      if (searchTerm !== "" && searchTerm?.length >= 2) {
        diseasesSearchTimeoutId = setTimeout(async () => {
          setIsAutocompleteLoading(true);

          let returnedArrayOfDiseases = [];

          if (searchTerm.length > 10) {
            searchTerm = searchTerm.slice(0, 10);
          }

          const diseasesResult = await claimsStore.getDiseases(searchTerm);
          returnedArrayOfDiseases = diseasesResult.map((d) => ({
            text: d.UserDescription,
            value: d.Code,
          }));

          setDiseases([...returnedArrayOfDiseases]);
          if (returnedArrayOfDiseases.length === 0) {
            setAutocompleteNoOptionText(autocompleteNoResultText);
          } else {
            setAutocompleteNoOptionText(defaultAutocompleteText);
          }
          setIsAutocompleteLoading(false);
        }, 1500);
      } else {
        setDiseases([]);
        setAutocompleteNoOptionText(defaultAutocompleteText);
        setIsAutocompleteLoading(false);
      }
    },
    [
      setDiseases,
      claimsStore,
      defaultAutocompleteText,
      autocompleteNoResultText,
      setAutocompleteNoOptionText,
      setIsAutocompleteLoading,
    ],
  );

  const validateExpenseCategoryValue = useCallback(
    (value) => {
      if (value !== "" && Object.values(ExpenseCategory).includes(value)) {
        setExpenseCategoryValueError("");
        return true;
      }
      setExpenseCategoryValueError(invalidValueText);
      return false;
    },
    [setExpenseCategoryValueError],
  );

  const validateEventDateValue = useCallback(
    (value) => {
      if (
        value &&
        value.toString() !== "" &&
        value.toString() !== "Invalid Date"
      ) {
        const dateValue = new Date(value);
        dateValue.setHours(0, 0, 0, 0);

        const minimumDateValue = new Date(minimumIncidentDate);
        minimumDateValue.setHours(0, 0, 0, 0);
        const maximumDateValue = new Date();
        maximumDateValue.setHours(0, 0, 0, 0);
        if (
          dateValue.getTime() - minimumDateValue.getTime() < 0 ||
          maximumDateValue.getTime() - dateValue.getTime() < 0
        ) {
          setEventDateValueError(invalidValueText);
          setEventDateValueIsValid(false);
          return false;
        }
        const dateValueStringFormatted = new Intl.DateTimeFormat("el-GR", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }).format(dateValue);
        const eventDateRegex = /^\d\d\/\d\d\/\d\d\d\d$/;
        if (eventDateRegex.test(dateValueStringFormatted)) {
          setEventDateValueError("");
          setEventDateValueIsValid(true);
          return true;
        }
        setEventDateValueError(invalidValueText);
        setEventDateValueIsValid(false);
        return false;
      }
      setEventDateValueError(invalidValueText);
      setEventDateValueIsValid(false);
      return false;
    },
    [minimumIncidentDate, setEventDateValueIsValid],
  );

  const validateEventDateBeforePolicyHolderStopPaymentDate = useCallback(
    (currentEventDateValue, currentContractValue) => {
      let currentContract;
      if (
        currentContractValue &&
        currentEventDateValue &&
        currentEventDateValue.toString() !== "" &&
        currentEventDateValue.toString() !== "Invalid Date"
      ) {
        currentContract = groupPoliciesWithEligibilitySubmission.find(
          (contract) => contract.PolicyNo === currentContractValue,
        );

        if (currentContract?.PolicyholderStopPaymentDate) {
          return moment(currentEventDateValue).isBefore(
            moment(currentContract?.PolicyholderStopPaymentDate),
          );
        }
      }
      return true;
    },
    [groupPoliciesWithEligibilitySubmission],
  );

  const validateBasicSubmissionInfo = useCallback(() => {
    const isContractValid = validateContractValue(contractValue);
    const isMainInsuredMemberValid = validateMainInsuredMemberValue(
      mainInsuredMemberValue,
    );
    const isClaimReasonValid = validateClaimReasonCode(claimReasonCode);
    const isExpenseCategoryValid =
      validateExpenseCategoryValue(expenseCategoryValue);
    const isEventDateValid = validateEventDateValue(eventDateValue);
    const isEventDateBeforePolicyHolderStopPaymentDate =
      validateEventDateBeforePolicyHolderStopPaymentDate(
        eventDateValue,
        contractValue,
      );
    return (
      isContractValid &&
      isMainInsuredMemberValid &&
      isClaimReasonValid &&
      isExpenseCategoryValid &&
      isEventDateBeforePolicyHolderStopPaymentDate &&
      isEventDateValid
    );
  }, [
    contractValue,
    validateContractValue,
    mainInsuredMemberValue,
    validateMainInsuredMemberValue,
    claimReasonCode,
    validateClaimReasonCode,
    expenseCategoryValue,
    validateExpenseCategoryValue,
    eventDateValue,
    validateEventDateValue,
    validateEventDateBeforePolicyHolderStopPaymentDate,
  ]);

  const handleEventDateChange = useCallback(
    (value) => {
      if (moment(value).isSame(eventDateValue)) {
        return;
      }

      if (validateEventDateValue(value)) {
        setEventDateValue(value);
      } else {
        setEventDateValue("");
      }
    },
    [eventDateValue, validateEventDateValue],
  );

  const enableNextPageBtn = useMemo(() => {
    return (
      termsAndConditionsValue === "Checked" &&
      validateEventDateBeforePolicyHolderStopPaymentDate(
        eventDateValue,
        contractValue,
      )
    );
  }, [
    termsAndConditionsValue,
    validateEventDateBeforePolicyHolderStopPaymentDate,
    eventDateValue,
    contractValue,
  ]);

  const handleContinueBtn = useCallback(() => {
    if (enableNextPageBtn && validateBasicSubmissionInfo()) {
      const dateValue = new Date(eventDateValue);
      claimsStore.setBasicSubmissionInfoToSubmit({
        contract: contractValue,
        insuredMember: mainInsuredMemberValue,
        claimReason: claimReasonValue,
        claimReasonCode: claimReasonCode,
        expenseCategory: expenseCategoryValue,
        eventDate: dateValue,
        termsAndConditions: termsAndConditionsValue === "Checked",
      });
      props.nextStep();
    }
  }, [
    enableNextPageBtn,
    contractValue,
    mainInsuredMemberValue,
    claimReasonValue,
    claimReasonCode,
    expenseCategoryValue,
    eventDateValue,
    claimsStore,
    termsAndConditionsValue,
    props,
    validateBasicSubmissionInfo,
  ]);

  const contractOptions = useMemo(() => {
    let options = [
      {
        text: `- ${t("global.choose")} -`,
        value: "",
      },
    ];

    eligibleContracts.forEach((contract) => {
      let name = contract.policyholderSurname ?? contract.productName;
      if (name.length > 30) {
        name = name.substring(0, 30);
        name += "...";
      }

      options.push({
        text: `${name} - ${contract.number}`,
        value: contract.number,
      });
    });

    return options;
  }, [eligibleContracts, language]);

  const autoSelectAutocompleteOptionIfAbsoluteMatch = useCallback(() => {
    const existingAbsoluteMatch = diseases.find(
      (elem) => elem.text.trim() === claimReasonValue.trim(),
    );
    if (existingAbsoluteMatch && claimReasonValueError !== "") {
      setClaimReasonCode(existingAbsoluteMatch.value);
      validateClaimReasonCode(existingAbsoluteMatch.value);
    }
  }, [
    diseases,
    claimReasonValue,
    setClaimReasonCode,
    claimReasonValueError,
    validateClaimReasonCode,
  ]);

  useEffect(() => {
    autoSelectAutocompleteOptionIfAbsoluteMatch();
  }, [autoSelectAutocompleteOptionIfAbsoluteMatch]);

  useEffect(() => {
    if (contractValueIsValid) {
      setMainInsuredMemberState("");
    } else {
      setMainInsuredMemberState("Disabled");
    }

    const isEventDateBeforePolicyHolderStopPaymentDate =
      validateEventDateBeforePolicyHolderStopPaymentDate(
        eventDateValue,
        contractValue,
      );
    if (!isEventDateBeforePolicyHolderStopPaymentDate) {
      setExpenseCategoryState("Disabled");
      setShowPolicyHolderStopPaymentDateError(true);
    } else if (
      contractValueIsValid &&
      eventDateValueIsValid &&
      policyBenefitsHasValue
    ) {
      setShowPolicyHolderStopPaymentDateError(false);
      setExpenseCategoryState("");
      if (expenseCategories.length === 0) {
        setShowNoGroupPolicyBenefitsModal(true);
        setExpenseCategoryState("Disabled");
      } else {
        setShowNoGroupPolicyBenefitsModal(false);
      }
    } else {
      setShowPolicyHolderStopPaymentDateError(false);
      setExpenseCategoryState("Disabled");
    }
  }, [
    contractValueIsValid,
    eventDateValueIsValid,
    policyBenefitsHasValue,
    setMainInsuredMemberState,
    setExpenseCategoryState,
    loaderStore,
    expenseCategories,
    validateEventDateBeforePolicyHolderStopPaymentDate,
    eventDateValue,
    contractValue,
  ]);

  useEffect(() => {
    if (
      groupContractsCoveragesHasContent &&
      groupPoliciesWithEligibilitySubmissionHasContent
    ) {
      const contracts = groupContractsCoverages.filter((contract) =>
        groupPoliciesWithEligibilitySubmission.find(
          (eligibilityContract) =>
            eligibilityContract.PolicyNo === contract.number &&
            eligibilityContract.IsEligibleForSubmission,
        ),
      );
      setEligibleContracts(contracts);
    }
  }, [
    groupContractsCoverages,
    groupContractsCoveragesHasContent,
    groupPoliciesWithEligibilitySubmission,
    groupPoliciesWithEligibilitySubmissionHasContent,
    setEligibleContracts,
  ]);

  useEffect(() => {
    setMainInsuredMemberValue("");
    if (selectedContract) {
      contractStore.loadCoverages(
        selectedContract.number,
        selectedContract.productCode,
        selectedContract.productCategoryId,
        true,
      );
    }
  }, [contractStore, selectedContract, setMainInsuredMemberValue]);

  useEffect(() => {
    let options = [
      {
        text: `- ${t("global.choose")} -`,
        value: "",
      },
    ];

    selectedContractDetails?.GroupPolicyMembers?.forEach((member) => {
      options.push({
        text: `${member.PersonName} ${member.PersonSurname} ${member.PersonRole}`,
        value: member.InsuredId,
      });
    });

    setInsuredMembers(options);
  }, [
    selectedContractDetails?.GroupPolicyMembers,
    setInsuredMembers,
    language,
  ]);

  useEffect(() => {
    setExpenseCategoryValue("");
    const isEventDateBeforePolicyHolderStopPaymentDate =
      validateEventDateBeforePolicyHolderStopPaymentDate(
        eventDateValue,
        contractValue,
      );

    if (
      contractValueIsValid &&
      eventDateValueIsValid &&
      isEventDateBeforePolicyHolderStopPaymentDate
    ) {
      const dateValue = new Date(eventDateValue);
      const dateValueStringFormatted = new Intl.DateTimeFormat("el-GR", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(dateValue);

      claimsStore.loadPolicyBenefits(
        contractValue,
        dateValueStringFormatted,
        mainInsuredMemberValue,
      );
    }
  }, [
    contractValue,
    contractValueIsValid,
    eventDateValue,
    eventDateValueIsValid,
    claimsStore,
    setExpenseCategoryValue,
    loaderStore,
    validateEventDateBeforePolicyHolderStopPaymentDate,
    mainInsuredMemberValue,
  ]);

  useEffect(() => {
    let expenseCategoryOptions = [
      {
        text: `- ${t("global.choose")} -`,
        value: "",
      },
    ];
    expenseCategories.forEach((element) => {
      expenseCategoryOptions.push({
        text: element.DisplayName,
        value: element.Name,
      });
    });
    setFilteredExpenseCategories(expenseCategoryOptions);
  }, [expenseCategories, setFilteredExpenseCategories, language]);

  useEffect(() => {
    let currentContract;
    if (contractValue) {
      currentContract = groupPoliciesWithEligibilitySubmission.find(
        (contract) => contract.PolicyNo === contractValue,
      );
    }
    let minimumDate = moment()
      .subtract(currentContract?.IncidentDateRangeMinMonths ?? 12, "months")
      .calendar();
    setMinimumIncidentDate(minimumDate);
  }, [
    contractValue,
    groupPoliciesWithEligibilitySubmission,
    setMinimumIncidentDate,
  ]);

  useEffect(() => {
    const isEventDateBeforePolicyHolderStopPaymentDate =
      validateEventDateBeforePolicyHolderStopPaymentDate(
        eventDateValue,
        contractValue,
      );
    if (
      contractValueIsValid &&
      eventDateValueIsValid &&
      isEventDateBeforePolicyHolderStopPaymentDate &&
      !policyBenefitsHasValue
    ) {
      loaderStore.setLoaderForData(true);
    } else {
      loaderStore.setLoaderForData(false);
    }
  }, [
    contractValueIsValid,
    eventDateValueIsValid,
    policyBenefitsHasValue,
    loaderStore,
    validateEventDateBeforePolicyHolderStopPaymentDate,
    eventDateValue,
    contractValue,
  ]);

  const renderTermsModal = useCallback(() => {
    return (
      <PrefabModal
        isOpen={showTermsModal}
        hasCloseButton
        children={
          <div className="nn-modal-content">
            <EclaimsTermsAndConditions />
          </div>
        }
        onClose={() => setShowTermsModal(false)}
        width="970px"
        height="auto"
        headingText={" "}
      />
    );
  }, [showTermsModal, setShowTermsModal]);

  const handleBackButton = useCallback(() => {
    if (claimsStore.basicSubmissionInfoToSubmit) {
      setShowBackConfirmationModal(true);
    } else {
      props.previousStep();
    }
  }, [
    claimsStore.basicSubmissionInfoToSubmit,
    props,
    setShowBackConfirmationModal,
  ]);

  function handleOnBlur(event) {
    let value = new Date(moment(event.target.value, "DD/MM/YYYY"));
    handleEventDateChange(value);
  }

  return (
    <div>
      {renderTermsModal()}
      <ConfirmationModal
        showConfirmationModal={showBackConfirmationModal}
        handleConfirm={() => {
          props.previousStep();
        }}
        handleCancel={() => {
          setShowBackConfirmationModal(false);
        }}
        message={t("claim.submission.confirmation-modal")}
      />
      <NoGroupPolicyBenefitsModal
        showNoGroupPolicyBenefitsModal={showNoGroupPolicyBenefitsModal}
        handleCancel={() => {
          setShowNoGroupPolicyBenefitsModal(false);
        }}
        message={dateOutOfRangeValidatioMessage}
        isHtml={true}
        title={t("claim.submission.expenses-search")}
      />
      <NoGroupPolicyBenefitsModal
        showNoGroupPolicyBenefitsModal={showPolicyHolderStopPaymentDateError}
        handleCancel={() => {
          setShowPolicyHolderStopPaymentDateError(false);
        }}
        isHtml={true}
        message={policyholderStopPaymentValidationMessage}
        title={t("claim.submission.expenses-search")}
      />
      <Heading mb="20px" isFirstSection>
        {t("claim.submission.registration-text")}
      </Heading>
      <div className={styles.formRowContainer}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <Select
            labelText={t("claim.submission.contract")}
            name="Contract"
            options={contractOptions}
            onChange={(event) => {
              setContractValue(event.target.value);
              validateContractValue(event.target.value);
              setSelectedContract(
                eligibleContracts.find(
                  (contract) => contract.number === event.target.value,
                ),
              );
            }}
            value={contractValue}
            validationText={contractValueError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`}>
          <Select
            dataState={mainInsuredMemberState}
            labelText={t("claim.submission.insured-member")}
            name="MainInsuredMember"
            options={insuredMembers}
            onChange={(event) => {
              setMainInsuredMemberValue(event.target.value);
              validateMainInsuredMemberValue(event.target.value);
            }}
            value={mainInsuredMemberValue}
            validationText={mainInsuredMemberValueError}
          />
        </div>
      </div>
      <div className={styles.formRowContainer}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <Autocomplete
            labelText={t("claim.submission.compensation-reason")}
            onChange={(text, value) => {
              const existingAbsoluteMatch = diseases.find(
                (elem) => elem.text.trim() === text.trim(),
              );
              if (existingAbsoluteMatch) {
                setClaimReasonValue(existingAbsoluteMatch.text);
                setClaimReasonCode(existingAbsoluteMatch.value);
                return;
              } else {
                setClaimReasonValue(text);
                setClaimReasonCode(value ?? "");
                handleOnChangeDiseasesAutoSearch(text);
              }
              validateClaimReasonCode(value);
            }}
            locale="elGR"
            options={diseases}
            noOptionsText={autocompleteNoOptionText}
            isLoading={isAutocompleteLoading}
            loadingText={t("global.loading")}
            testId="auto-complete"
            value={claimReasonValue}
            variant="Search"
            validationText={claimReasonValueError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`}>
          <DatepickerInput
            defaultView="Day"
            labelText={t("claim.table.header.event-date")}
            name="EventDate"
            placeholderText="ΗΗ/ΜΜ/ΕΕΕΕ"
            locale="elGR"
            inputFormat="dd/MM/yyyy"
            views={[
              {
                name: "Year",
              },
              {
                name: "Month",
              },
              {
                name: "Day",
              },
            ]}
            onBlur={handleOnBlur}
            onAccept={handleEventDateChange}
            minDate={minimumIncidentDate}
            maxDate={new Date()}
            value={eventDateValue}
            validationText={eventDateValueError}
          />
        </div>
      </div>
      <div className={styles.formRowContainer}>
        <div className={`${styles.inputElement} ${styles.firstElement}`}>
          <Select
            dataState={expenseCategoryState}
            labelText={t("claim.submission.output-class")}
            name="ExpenseCategory"
            options={filteredExpenseCategories}
            popoverElement={
              <Popover
                headingText=" "
                placement="bottom-start"
                testId="popover"
                variant="Icon"
              >
                <div>
                  {contractValueIsValid &&
                  eventDateValueIsValid &&
                  expenseCategories.length > 0 ? (
                    expenseCategories.map((element) => (
                      <div
                        key={element.Name}
                        className={styles.expenseCategoryPopoverSection}
                      >
                        <Label text={`${element.DisplayName}: `} />
                        <Paragraph>
                          {element.Description.replace(/<[^>]+>/g, "")}
                        </Paragraph>
                      </div>
                    ))
                  ) : (
                    <Paragraph>
                      {t("claim.submission.contract-date-first")}
                    </Paragraph>
                  )}
                </div>
              </Popover>
            }
            onChange={(event) => {
              setExpenseCategoryValue(event.target.value);
              validateExpenseCategoryValue(event.target.value);
              claimsStore.clearClaimsListToSubmit();
              claimsStore.clearSelectedClaimToEdit();
            }}
            value={expenseCategoryValue}
            validationText={expenseCategoryValueError}
          />
        </div>
        <div className={`${styles.inputElement} ${styles.secondElement}`} />
      </div>
      <div className={styles.termsConditions}>
        <Checkbox
          variant="Small"
          labelText={t("claim.submission.read-accept")}
          name="termsConditions"
          onChange={(event) => {
            setTermsAndConditionsValue(
              event.target.checked ? "Checked" : "NotChecked",
            );
          }}
          dataState={termsAndConditionsValue}
          mb="20px"
          mt="3px"
        />
        <Button
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setShowTermsModal(true);
          }}
          variant="PlainSmall"
          mt="-2"
          mb="20px"
          ml="6px"
        >
          {t("global.terms_of_use")}
        </Button>
      </div>
      <div className={styles.buttonsRow}>
        <div className={styles.previousStepBtn}>
          <Button
            type="Button"
            onClick={() => {
              handleBackButton();
            }}
          >
            {t("global.back")}
          </Button>
        </div>
        <div
          className={`${styles.nextStepBtn} ${
            enableNextPageBtn ? "" : styles.disabledBtn
          }`}
        >
          <Button
            type="Button"
            onClick={() => {
              handleContinueBtn();
            }}
          >
            {t("global.continue")}
          </Button>
        </div>
      </div>
      <div className={styles.compulsoryNote}>
        <Label text={t("claim.submission.required-fields")} />
      </div>
    </div>
  );
};

export default observer(BasicSubmissionInfo);
