import { action, makeObservable, observable } from "mobx";
import { createContext } from "react";
import moment from "moment";
import agent from "../Api/agent";
import { ClaimState, ExpenseType } from "../Consts/Claims";

class ClaimStore {
  claims = null;
  claimsHasContent = false;
  basicSubmissionInfoToSubmit = null;
  claimsListToSubmit = [];
  selectedClaimToEdit = null;
  storedDiseases = {};
  groupPoliciesWithEligibilitySubmissionHasContent = false;
  groupPoliciesWithEligibilitySubmission = [];
  expenseCategories = [];
  expenseTypes = [];
  storedPolicyBenefits = [];
  policyBenefitsHasValue = false;
  usedCoverCode = null;
  diagnosticCenters = [];
  prefectures = [];
  storedDiagnosticCenters = [];
  documentTypes = [];
  doctorSpecialities = [];
  isConnectingToSignalR = false;
  claimState = ClaimState.notSubmitted;

  constructor() {
    makeObservable(this, {
      claims: observable,
      claimsHasContent: observable,
      basicSubmissionInfoToSubmit: observable,
      claimsListToSubmit: observable,
      selectedClaimToEdit: observable,
      storedDiseases: observable,
      groupPoliciesWithEligibilitySubmissionHasContent: observable,
      groupPoliciesWithEligibilitySubmission: observable,
      expenseCategories: observable,
      expenseTypes: observable,
      storedPolicyBenefits: observable,
      policyBenefitsHasValue: observable,
      usedCoverCode: observable,
      diagnosticCenters: observable,
      prefectures: observable,
      storedDiagnosticCenters: observable,
      documentTypes: observable,
      doctorSpecialities: observable,
      isConnectingToSignalR: observable,
      claimState: observable,

      getMyClaims: action,
      getDiseases: action,
      setBasicSubmissionInfoToSubmit: action,
      clearBasicSubmissionInfoToSubmit: action,
      addClaimToSubmitingList: action,
      removeClaimFroSubmitingList: action,
      clearClaimsListToSubmit: action,
      setSelectedClaimToEdit: action,
      clearSelectedClaimToEdit: action,
      loadGroupPoliciesWithEligibilitySubmission: action,
      loadDiagnosticCenters: action,
      loadDocumentTypes: action,
      loadDoctorSpecialities: action,
      mapClaimsToSubmit: action,
      submitClaim: action,
      uploadFile: action,
      setIsConnectingToSignalR: action,
      setClaimSubmissionState: action,
      handleNewStatus: action,
      handleClaimSubmissionTimeout: action,
      resubmitClaimFiles: action,
    });
  }

  getMyClaims = () => {
    this.claimsHasContent = false;
    let claimResponse = agent.claims.getMyClaims();
    claimResponse
      .then((result) => {
        this.claims = { ...result };
        this.claimsHasContent = true;
      })
      .catch((error) => {
        this.claimsHasContent = true;
      });
  };

  getDiseases = async (keyword) => {
    let diseasesToReturn = [];
    if (this.storedDiseases[keyword]) {
      diseasesToReturn = this.storedDiseases[keyword];
    } else {
      try {
        const result = await agent.claims.getDiseases(keyword);
        diseasesToReturn = result.ClaimDiseasesList;
        this.storedDiseases[keyword] = diseasesToReturn;
      } catch (error) {
        console.log(error);
      }
    }
    return diseasesToReturn;
  };

  setBasicSubmissionInfoToSubmit = (value) => {
    this.basicSubmissionInfoToSubmit = { ...value };
  };

  clearBasicSubmissionInfoToSubmit = () => {
    this.basicSubmissionInfoToSubmit = null;
  };

  addClaimToSubmitingList = (claim) => {
    this.claimsListToSubmit = [
      ...this.claimsListToSubmit,
      { Id: Date.now(), ...claim },
    ];
    if (!this.usedCoverCode) {
      this.usedCoverCode = this.expenseTypes?.find(
        (et) => et.Name === claim.ExpenseType,
      )?.CoverCode;
    }
  };

  removeClaimFroSubmitingList = (claimId) => {
    this.claimsListToSubmit = this.claimsListToSubmit.filter(
      (claim) => claim.Id !== claimId,
    );
    if (this.claimsListToSubmit.length <= 0) {
      this.usedCoverCode = null;
    }
  };

  clearClaimsListToSubmit = () => {
    this.usedCoverCode = null;
    this.claimsListToSubmit = [];
    this.isConnectingToSignalR = false;
    this.setClaimSubmissionState(ClaimState.notSubmitted);
  };

  setSelectedClaimToEdit = (value) => {
    this.selectedClaimToEdit = { ...value };
  };

  clearSelectedClaimToEdit = () => {
    this.selectedClaimToEdit = null;
  };

  saveEditedClaim = (value) => {
    const editedClaimIndex = this.claimsListToSubmit.findIndex(
      (claim) => claim.Id === value.Id,
    );
    this.claimsListToSubmit[editedClaimIndex] = { Id: value.Id, ...value };
  };

  loadGroupPoliciesWithEligibilitySubmission = () => {
    this.groupPoliciesWithEligibilitySubmissionHasContent = false;
    let response = agent.claims.getGroupPoliciesWithEligibilitySubmission();
    response
      .then((result) => {
        this.groupPoliciesWithEligibilitySubmission = [...result.Data];
        this.groupPoliciesWithEligibilitySubmissionHasContent = true;
      })
      .catch((ex) => {
        this.groupPoliciesWithEligibilitySubmissionHasContent = true;
      });
  };

  loadPolicyBenefits = (policyId, referenceDate, insuredId) => {
    this.policyBenefitsHasValue = false;

    const storedPolicy = this.storedPolicyBenefits.find(
      (sp) =>
        sp.policyId === policyId &&
        sp.referenceDate === referenceDate &&
        sp.insuredId === insuredId,
    );

    if (storedPolicy) {
      this.expenseCategories = storedPolicy.result.ExpenseCategories;
      this.expenseTypes = storedPolicy.result.ExpenseTypes;
      this.policyBenefitsHasValue = true;
    } else {
      const response = agent.claims.getPolicyBenefits(referenceDate, insuredId);
      response
        .then((result) => {
          this.policyBenefitsHasValue = true;
          this.expenseCategories = result.ExpenseCategories;
          this.expenseTypes = result.ExpenseTypes;
          this.storedPolicyBenefits.push({
            policyId,
            referenceDate,
            insuredId,
            result,
          });
        })
        .catch((ex) => (this.policyBenefitsHasValue = true));
    }
  };

  loadDiagnosticCenters = (policyId, referenceDate) => {
    const storedCenters = this.storedDiagnosticCenters.find(
      (s) => s.policyId === policyId && s.referenceDate === referenceDate,
    );
    if (storedCenters) {
      this.diagnosticCenters = storedCenters.result.DiagnosticCenters;
      this.prefectures = storedCenters.result.Prefectures;
    } else {
      const response = agent.claims.getDiagnosticCenters(
        policyId,
        referenceDate,
      );
      response
        .then((result) => {
          this.diagnosticCenters = result.DiagnosticCenters;
          this.prefectures = result.Prefectures;
          this.storedDiagnosticCenters.push({
            policyId,
            referenceDate,
            result,
          });
        })
        .catch((ex) => {});
    }
  };

  loadDocumentTypes = () => {
    let response = agent.claims.getDocumentTypes();
    response
      .then((result) => {
        this.documentTypes = [...result.DocumentTypes];
      })
      .catch((ex) => {});
  };

  loadDoctorSpecialities = () => {
    let response = agent.claims.getDoctorSpecialities();
    response
      .then((result) => {
        this.doctorSpecialities = [...result.MedicalSpecialityList];
      })
      .catch((ex) => {});
  };

  mapClaimsToSubmit = (connectionId) => {
    const body = {};
    body.ClaimBasicInfo = this.basicSubmissionInfoToSubmit;
    body.ClaimBasicInfo.eventDate = moment(
      this.basicSubmissionInfoToSubmit.eventDate,
    ).format("YYYY-MM-DD");
    body.signalRConnectionId = connectionId;
    body.Claims = this.claimsListToSubmit.map((claim) => {
      const expenseName =
        claim.ExpenseType === ExpenseType.NormalMaternityAllowance ||
        claim.ExpenseType === ExpenseType.CaesareanMaternityAllowance ||
        claim.ExpenseType === ExpenseType.MiscarriageMaternityAllowance
          ? "MaternityAllowance"
          : claim.ExpenseType;

      const currentClaim = {
        ExpenseType: claim.ExpenseType,
        [expenseName]: {},
      };

      if (claim.ExpenseType === ExpenseType.MedicalVisit) {
        currentClaim[expenseName] = {
          CollaboratingPhysician: claim.CollaboratingPhysician,
          AtClinic: claim.AtClinic,
          AtHome: claim.AtHome,
          MedicalActionsPerformed: claim.MedicalActionsPerformed,
          DoctorsSpeciality: claim.DoctorsSpeciality,
          ReceiptDate: moment(claim.ReceiptDate).format("YYYY-MM-DD"),
          ReceiptAmount: claim.ReceiptAmount,
          OtherInsurerContributionAmount: claim.OtherInsurerContributionAmount,
          ReceiptNumber: claim.ReceiptNumber,
        };
      }

      if (claim.ExpenseType === ExpenseType.DiagnosticExamination) {
        currentClaim[expenseName] = {
          ExaminationLocation: claim.ExaminationLocation,
          Center: claim.Center,
          ReceiptDate: moment(claim.ReceiptDate).format("YYYY-MM-DD"),
          ReceiptAmount: claim.ReceiptAmount,
          OtherInsurerContributionAmount: claim.OtherInsurerContributionAmount,
          ReceiptNumber: claim.ReceiptNumber,
        };
      }

      if (claim.ExpenseType === ExpenseType.Pharmacies) {
        currentClaim[expenseName] = {
          PhysicianSpecialty: claim.PhysicianSpecialty,
          ReceiptDate: moment(claim.ReceiptDate).format("YYYY-MM-DD"),
          ReceiptAmount: claim.ReceiptAmount,
          OtherInsurerContributionAmount: claim.OtherInsurerContributionAmount,
          ReceiptNumber: claim.ReceiptNumber,
        };
      }

      if (claim.ExpenseType === ExpenseType.PhysicalTherapy) {
        currentClaim[expenseName] = {
          HasReferral: claim.HasReferral,
          CollaboratingPhysiotherapist: claim.CollaboratingPhysiotherapist,
          NumberOfSessions: claim.NumberOfSessions,
          ReceiptDate: moment(claim.ReceiptDate).format("YYYY-MM-DD"),
          ReceiptAmount: claim.ReceiptAmount,
          OtherInsurerContributionAmount: claim.OtherInsurerContributionAmount,
          ReceiptNumber: claim.ReceiptNumber,
        };
      }

      if (
        claim.ExpenseType === ExpenseType.HospitalAllowance ||
        claim.ExpenseType === ExpenseType.SurgicalAllowance
      ) {
        currentClaim[expenseName] = {
          EntryDate: moment(claim.EntryDate).format("YYYY-MM-DD"),
          ExitDate: moment(claim.ExitDate).format("YYYY-MM-DD"),
        };
      }

      if (
        claim.ExpenseType === ExpenseType.NormalMaternityAllowance ||
        claim.ExpenseType === ExpenseType.CaesareanMaternityAllowance ||
        claim.ExpenseType === ExpenseType.MiscarriageMaternityAllowance
      ) {
        currentClaim[expenseName] = {
          ChildBirthType: claim.ChildBirthType,
        };
      }

      if (claim.ExpenseType === ExpenseType.OutOfHospitalMedicalProcedures) {
        currentClaim[expenseName] = {
          DoctorsSpeciality: claim.DoctorsSpeciality,
          ReceiptDate: moment(claim.ReceiptDate).format("YYYY-MM-DD"),
          ReceiptAmount: claim.ReceiptAmount,
          OtherInsurerContributionAmount: claim.OtherInsurerContributionAmount,
          ReceiptNumber: claim.ReceiptNumber,
        };
      }

      currentClaim[expenseName].SubmittedFiles = claim.Files?.map((file) => ({
        DocumentFileId: file.fileId,
        DocumentFileName: file.fileName,
        DocumentFileType: file.fileType,
      }));
      return currentClaim;
    });

    return body;
  };

  getClaimState = (isSuccess, connectionId) => {
    if (isSuccess) {
      if (connectionId) return ClaimState.processing;
      else return ClaimState.timedOut;
    } else return ClaimState.failed;
  };

  submitClaim = (connectionId) => {
    this.setClaimSubmissionState(ClaimState.submitting);
    const requestObject = this.mapClaimsToSubmit(connectionId);
    let response = agent.claims.submitClaims(requestObject);
    response
      .then((result) => {
        this.setClaimSubmissionState(
          this.getClaimState(result.IsSuccess, connectionId),
        );
      })
      .catch((ex) => {
        this.setClaimSubmissionState(ClaimState.failed);
      });
  };

  uploadFile = (
    file,
    applicationNumber,
    contractNumber,
    productCode,
    ownerClientNumber,
  ) => {
    const formData = new FormData();
    formData.append("files[]", file);
    formData.append("applicationnumber", applicationNumber);
    formData.append("contractnumber", contractNumber);
    formData.append("productcode", productCode);
    formData.append("ownerclientnumber", ownerClientNumber);

    const response = agent.claims.fileupload(formData);

    return new Promise((resolve, reject) => {
      response
        .then((result) => {
          if (result.Success) {
            resolve(result.DocumentId);
          } else {
            resolve("");
          }
        })
        .catch((ex) => {
          resolve("");
        });
    });
  };

  setIsConnectingToSignalR = (value) => {
    this.isConnectingToSignalR = value;
  };

  setClaimSubmissionState = (value) => {
    this.claimState = value;
  };

  handleNewStatus = (status) => {
    if (this.claimState === ClaimState.processing) {
      let newState;

      if (status === "Success") newState = ClaimState.successful;
      else if (status === "Decline") newState = ClaimState.failed;
      else if (status === "InProgress") newState = ClaimState.inProgress;
      else {
        console.error(`handleSubmissionResponse: Unhandled Status ${status}`);
        return;
      }
      this.setClaimSubmissionState(newState);
    }
  };

  handleClaimSubmissionTimeout = () => {
    if (this.claimState === ClaimState.processing) {
      this.setClaimSubmissionState(ClaimState.timedOut);
    }
  };

  resubmitClaimFiles = async (ClaimNo, SourceSystemCaseNumber, files) => {
    try {
      const result = await agent.claims.resubmitClaimFiles({
        ClaimNo,
        SourceSystemCaseNumber,
        Files: files.map((file) => ({
          documentFileId: file.fileId,
          documentFileName: file.fileName,
          documentFileType: file.fileType,
        })),
      });

      return result.IsSuccess;
    } catch (ex) {
      return false;
    }
  };
}
export default createContext(new ClaimStore());
