export const NAVBAR_ITEMS = [
  {
    Url: "/home",
    Target: "_blank",
    Title: "Αρχική",
    Location: "",
    NodeAlias: "Home",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItems/Home",
  },
  {
    Url: "/myContracts",
    Target: "_blank",
    Title: "Τα Συμβόλαιά μου",
    Location: "",
    NodeAlias: "myContracts",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItems/myContracts",
  },
  {
    Url: "/myPayments",
    Target: "_blank",
    Title: "Οι Πληρωμές μου",
    Location: "",
    NodeAlias: "myPayments",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItems/myPayments",
  },
  {
    Url: "/myInbox",
    Target: "_blank",
    Title: "Τα Μηνύματά μου",
    Location: "",
    NodeAlias: "myInbox",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItems/myInbox",
  },
  {
    Url: "/myDocuments",
    Target: "_blank",
    Title: "Τα Έγγραφά μου",
    Location: "",
    NodeAlias: "myDocuments",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItems/myDocuments",
  },
  {
    Url: "/myClaims",
    Target: "_self",
    Title: "Οι Αποζημιώσεις μου",
    Location: "",
    NodeAlias: "Refunds",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItems/Refunds",
  },
  {
    Url: "/online-payment",
    Target: "_blank",
    Title: "Online Πληρωμή",
    Location: "",
    NodeAlias: "OnlinePayment",
    DocumentMenuClass: "online-payment",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItems/OnlinePayment",
  },
];

export const NAVBAR_ITEMS_HR = [
  {
    Url: "/home",
    Target: "_blank",
    Title: "Αρχική",
    Location: "main-menu",
    NodeAlias: "home",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/HrMenuItems/home",
  },
  {
    Url: "/hr/contracts",
    Target: "_blank",
    Title: "Συμβόλαια",
    Location: "main-menu",
    NodeAlias: "Contracts",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/HrMenuItems/Contracts",
  },
  {
    Url: "/hr/payments",
    Target: "_blank",
    Title: "Πληρωμές",
    Location: "main-menu",
    NodeAlias: "payments",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/HrMenuItems/payments",
  },
];

export const NAVBAR_ITEMS_MOBILE = [
  {
    Url: "/home",
    Target: "_blank",
    Title: "Αρχική",
    Location: "",
    NodeAlias: "Home",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItemsMobile/Home",
  },
  {
    Url: "/myContracts",
    Target: "_blank",
    Title: "Τα Συμβόλαιά μου",
    Location: "",
    NodeAlias: "myContracts",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItemsMobile/myContracts",
  },
  {
    Url: "/myPayments",
    Target: "_blank",
    Title: "Οι Πληρωμές μου",
    Location: "",
    NodeAlias: "myPayments",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItemsMobile/myPayments",
  },
  {
    Url: "/myInbox",
    Target: "_blank",
    Title: "Τα Μηνύματά μου",
    Location: "",
    NodeAlias: "myInbox",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItemsMobile/myInbox",
  },
  {
    Url: "/myDocuments",
    Target: "_blank",
    Title: "Τα Έγγραφά μου",
    Location: "",
    NodeAlias: "myDocuments",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItemsMobile/myDocuments",
  },
  {
    Url: "/myClaims",
    Target: "_self",
    Title: "Οι Αποζημιώσεις μου",
    Location: "",
    NodeAlias: "Refunds",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItemsMobile/Refunds",
  },
  {
    Url: "/Support",
    Target: "_blank",
    Title: "Υποστήριξη",
    Location: "",
    NodeAlias: "Support",
    DocumentMenuClass: "",
    NodeAliasPath: "/Settings/NavigationMenus/MainMenuItemsMobile/Support",
  },
  {
    Url: "/online-payment",
    Target: "_blank",
    Title: "Online Πληρωμή",
    Location: "header",
    NodeAlias: "OnlinePayment",
    DocumentMenuClass: "online-payment",
    NodeAliasPath:
      "/Settings/NavigationMenus/MainMenuItemsMobile/OnlinePayment",
  },
];
