import React, { useContext, useEffect, useState } from "react";
import SpecialPagesStore from "../../Stores/SpecialPagesStore";
import dompurify from "dompurify";
import UserStore from "../../Stores/UserStore";
import { observer } from "mobx-react-lite";

const TermsAndConditions = () => {
  const specialPagesStore = useContext(SpecialPagesStore);
  const { isAuthorized } = useContext(UserStore);
  const { infoPageResponse, infoPublicPageResponse } = specialPagesStore;
  const [content, setContent] = useState();
  const [titleArray, setTitleArray] = useState();
  const [classArray, setClassArray] = useState(["light", "normal", "dark"]);
  const sanitizer = dompurify.sanitize;

  const setResponseArray = (valArray) => {
    let strigArray = valArray.Title.split(" ");
    if (strigArray.length > 3) {
      let combineArray = [...strigArray];
      strigArray = [];
      if (combineArray.length === 4) {
        strigArray.push(combineArray[0] + " " + combineArray[1]);
        strigArray.push(combineArray[2]);
        strigArray.push(combineArray[3]);
      }
      if (combineArray.length === 5) {
        strigArray.push(combineArray[0] + " " + combineArray[1]);
        strigArray.push(combineArray[2] + " " + combineArray[3]);
        strigArray.push(combineArray[4]);
      }
      if (combineArray.length === 6) {
        strigArray.push(combineArray[0] + " " + combineArray[1]);
        strigArray.push(combineArray[2] + " " + combineArray[3]);
        strigArray.push(combineArray[4] + " " + combineArray[5]);
      }
    }
    return strigArray;
  };

  useEffect(() => {
    if (isAuthorized) {
      specialPagesStore.loadInfoPage("/Info/TermsAndConditions");
    }
    if (isAuthorized === false) {
      specialPagesStore.loadInfoPublicPage("/Info/TermsAndConditions");
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (infoPageResponse) {
      setContent(infoPageResponse);
      let strigArray = setResponseArray(infoPageResponse);
      setTitleArray(strigArray);
    }
  }, [infoPageResponse]);

  useEffect(() => {
    if (infoPublicPageResponse) {
      setContent(infoPublicPageResponse);
      let strigArray = setResponseArray(infoPublicPageResponse);
      setTitleArray(strigArray);
    }
  }, [infoPublicPageResponse]);

  return (
    <>
      {content ? (
        <>
          <h2 className="modal-title">
            {titleArray.map((elementTitle, i) => {
              return (
                <span key={i} className={classArray[i]}>
                  {elementTitle}{" "}
                </span>
              );
            })}
          </h2>
          <div dangerouslySetInnerHTML={{ __html: sanitizer(content.Text, { ADD_ATTR: ["target"] }) }} />
        </>
      ) : null}
    </>
  );
};

export default observer(TermsAndConditions);
